import styles from './App.module.scss';
import {BrowserRouter, Link, Router, Route, Routes} from 'react-router-dom';
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import Footer from "./components/Footer/Footer";
import About from "./components/About/About";
import NewsHome from "./components/NewsHome/NewsHome";
import NewPage from "./components/NewPage/NewPage";
import Images from "./components/Images/Images";
import Contact from "./components/Contact/Contact";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header></Header>
        <Routes>
          <Route path={'/'} element={
            <>
              <Home></Home>
              <About></About>
              <NewsHome></NewsHome>
              <Images></Images>
              <Contact></Contact>
            </>
          }></Route>
          <Route path={'/news'} element={
            <>
              <NewPage></NewPage>
            </>
          }></Route>
        </Routes>
        <Footer></Footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
