import React from "react";
import styles from "./New.module.scss";

const New = (props) => {

  return (
    <a href={`/news?image=${props.image}&heading=${props.heading}&text=${props.text}`} className={styles.container}>
      <img src={props.image}/>
      <h3>{props.heading}</h3>
    </a>
  )
}

export default New;