import React, {useRef, useState} from "react";
import styles from "./Contact.module.scss";
import emailjs from '@emailjs/browser';

const Contact = () => {
  const form = useRef();

  const[error, setError] = useState(false);
  const[sent, setSent] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_0tf3mnv', 'template_p6m70gi', form.current, {
        publicKey: 'NwcWRfaDbU1UxC2EU',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setError(false);
          setSent(true);
        },
        (error) => {
          console.log('FAILED...', error.text);
          setError(true);
          setSent(true);
        },
      );
  };

  return (
    <div className={styles.container} id={"kontakt"}>
      <h1>Контакт</h1>
      <form ref={form} onSubmit={sendEmail}>
        <p>Мејл: office.kudprelo@gmail.com</p>
        <p>Тел: 063354222</p>
        <h4>Попуни форму и учлани се!</h4>
        <label>Име и презиме родите<span className={styles.lj}>љ</span>а</label>
        <input type="text" name="ime_roditelja" />
        <label>Име и презиме детета</label>
        <input type="text" name="ime_deteta" />
        <label>Године детета</label>
        <input type="text" name="godine_deteta" />
        <label>Контакт телефон</label>
        <input type="text" name="telefon" />
        <label>Порука</label>
        <textarea name="message" />
        <input className={styles.btn} type="submit" value="Учлани се" />
        {
          sent && !error ? <h3 id={styles.success}>Успешно послато!</h3> :
            sent && error ? <h3 id={styles.error}>Дошло је до грешке...</h3> :
              <></>
        }
      </form>
    </div>
  );
}

export default Contact;