import React from "react";
import styles from "./About.module.scss";

const About = () => {
  return (
    <div className={styles.container} id={"onama"}>
      <h1>О нама</h1>
      <p>Већ дуги низ година негујемо традиционалне вредности у нашем културно-уметничком друштву! Наша заједница је место где се наши чланови окуп<span className={styles.lj}>љ</span>ају и деле <span className={styles.lj}>љ</span>убав према фолклору. Уз путова<span className={styles.lj}>њ</span>а, друже<span className={styles.lj}>њ</span>е, забаву и могућности за упознава<span className={styles.lj}>њ</span>е нових <span className={styles.lj}>љ</span>уди, нудимо вам и незаборавно искуство.
        <br/><br/>
        КУД „Прело", културно уметничко друштво омладине Великог Села и Сланаца код Београда. Настали смо при цркви Светог првомученика и архиђакона Стефана у чијем парохијском дому смо и почели са радом уз благослов протојереја ставрофора Раденка Жива<span className={styles.lj}>љ</span>евићa.
        <br/><br/>
        Наше друштво је поде<span className={styles.lj}>љ</span>ено у три секције: драмску секцију - Драмска група "Житије", певачку секцију - Певачка група "Милице" и фолклорну секцију која је и најбројнија. Фолклорну секцију чине четири ансамбла: дечији, омладински, први и ансамбл ветерана. На репертоару имамо игре из разних делова Србије: Игре из Шумадије, Студенице, Лесковца, Пирота, Сокоба<span className={styles.lj}>њ</span>е, Косовске Каменице, Црноречја....
        <br/><br/>
        Ци<span className={styles.lj}>љ</span> нам је да склонимо децу са улице и понудимо им нешто заним<span className={styles.lj}>љ</span>ивије, здравије и лепше, где ће кроз игру, глуму, песму и друже<span className={styles.lj}>њ</span>е научити доста тога о нашој култури и традицији и правилно се развити. Уједно радимо на очува<span className={styles.lj}>њ</span>у и презентова<span className={styles.lj}>њ</span>у наше културе и традиције, како у зем<span className={styles.lj}>љ</span>и тако и у иностранству. До сада смо организовали доста наступа, како у Београду, тако и у целој Србији.
        <br/><br/>
        На бројним међународним фестивалима смо освајали прва места.
        <br/><br/>
        Ове године планирамо фестивал који ће трајати два дана. Биће одржан на спортском терену у центру Сланаца. Фестивал ће се звати „Прело у нашем крају". Фестивал има за ци<span className={styles.lj}>љ</span> да подстакне децу на друже<span className={styles.lj}>њ</span>е, да им пробуди свест о нашој традицији, култури и важности <span className={styles.lj}>њ</span>иховог очува<span className={styles.lj}>њ</span>а.</p>
    </div>
  )
}

export default About;