import styles from "./Home.module.scss";
import React from "react";

const Home = () => {
  return (
    <div className={styles.container}>
      <h1>КУД ПРЕЛО</h1>
      <p>КУЛТУРНО УМЕТНИЧКО ДРУШТВО ПРЕЛО</p>
      <a className={styles.btn} href={'#kontakt'}>УЧЛАНИ СЕ</a>
    </div>
  )
}

export default Home;