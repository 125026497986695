import React from "react";
import styles from "./NewsHome.module.scss";
import New from "../New/New";
import img1 from "../../images/pozadina.jpg";
import img2 from "../../images/pozadina2.jpg";

const NewsHome = () => {

  const img1src = "../../images/pozadina.png";
  const img2src = "../../images/pozadina2.png";

  const news = [
    // {
    //   image: img1,
    //   heading: "Ово је први наслов",
    //   text: "Ово је први текст.",
    //   imgsrc: img1src
    // },
    // {
    //   image: img2,
    //   heading: "Ово је други наслов",
    //   text: "Ово је други текст",
    //   imgsrc: img2src
    // }
  ]

  return (
    <div className={styles.container} id={"vesti"}>
      <h1>Вести</h1>
      <div className={styles.container2}>
      {
        news.map((new1) => <New image={new1.image}
        heading={new1.heading}
        text={new1.text}
        imgsrc={new1.imgsrc}></New>)
      }
      За сада нема вести...
      </div>
    </div>
  )
}

export default NewsHome;