import React from "react";
import styles from "./NewPage.module.scss";
import {useSearchParams} from "react-router-dom";
import slika from "../../images/grb.png";

const NewPage = () => {

  const[searchParams, setSearchParams] = useSearchParams();

  const image = searchParams.get("image");
  const heading = searchParams.get("heading");
  const text = searchParams.get("text");

  console.log(image);

  return (
    <>
      <div style={{background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${image})`}} className={styles.container}>

      </div>
      <div className={styles.headingBox}>
        <h1>{heading}</h1>
        <p>ЧИТАЈ ВИШЕ ИСПОД</p>
      </div>

      <p id={styles.paragraf}>{text}</p>
    </>
  )
}

export default NewPage;