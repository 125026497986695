import React, {useState} from "react";
import style from './Header.module.scss';
import logo from "../../images/grb.png";

const Header = () => {

    const[scroll, setScroll] = useState(false);

    window.addEventListener("scroll", () => {
        if (window.scrollY > 500)
            setScroll(true)
        else
            setScroll(false)
    })

    const[menuClicked, setMenuClicked] = useState(false);

    const menuClick = () => {
        if (menuClicked)
            setMenuClicked(false)
        else
            setMenuClicked(true)
    }

    return (
      <div className={scroll ? style.header + " " + style.scroll : style.header}>
          <a href={'/'} className={style.logo}>
              <img src={logo}/>
              <span>КУД Прело</span>
          </a>
          <a onClick={menuClick} className={menuClicked ? style.menu + " " + style.clicked : style.menu}>
              <div id={style.firstLine} className={style.line}></div>
              <div id={style.secondLine} className={style.line}></div>
              <div id={style.thirdLine} className={style.line}></div>
          </a>
          <ul className={menuClicked ? style.navigation + " " + style.shown : style.navigation}>
              <li>
                  <a onClick={menuClick} href={'/'}>Почетна</a>
              </li>
              <li>
                  <a onClick={menuClick} href={'#onama'}>О нама</a>
              </li>
              {/*<li>*/}
              {/*  <a href={'/'}>My Work</a>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*    <a onClick={menuClick} href={'https://github.com/SaloJovanovic'} target={"_blank"}>GitHub</a>*/}
              {/*</li>*/}
              <li>
                  <a onClick={menuClick} href={'#vesti'}>Вести</a>
              </li>
              <li>
                  <a onClick={menuClick} href={'#slike'}>Слике</a>
              </li>
              <li>
                  <a onClick={menuClick} href={'#kontakt'}>Контакт</a>
              </li>
          </ul>
      </div>
    )
}

export default Header;