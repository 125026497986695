import React, {useRef, useState} from "react";
import styles from "./Footer.module.scss";
import grb from "../../images/grb.png";
import GoogleMap from 'google-maps-react-markers'
import { Marker } from "react-simple-maps";

const Footer = () => {

  const mapRef = useRef(null)
  const [mapReady, setMapReady] = useState(false)

  /**
   * @description This function is called when the map is ready
   * @param {Object} map - reference to the map instance
   * @param {Object} maps - reference to the maps library
   */
  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map
    setMapReady(true)
  }

  const onMarkerClick = (e, { markerId, lat, lng }) => {
    console.log('This is ->', markerId)

    // inside the map instance you can call any google maps method
    mapRef.current.setCenter({ lat, lng })
    // ref. https://developers.google.com/maps/documentation/javascript/reference?hl=it
  }

  return (
    <div className={styles.container}>
      <img src={grb}/>
      <div className={styles.info}>
        <p>Културно уметничко друштво „Прело"</p>
        <p>Тел: 063354222</p>
        <p>Мејл: office.kudprelo@gmail.com</p>
        <p>Адреса: Београдска 79, Београд</p>
        <a href={'https://www.instagram.com/kudprelo/'}>Инстаграм</a><br/>
        <a href={'https://www.facebook.com/KUDPrel0'}>Фејсбук</a>
      </div>
    </div>
  )
}

export default Footer;