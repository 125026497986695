import React from "react";
import styles from "./Images.module.scss";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";

const images = [
  {
    original: "https://i.postimg.cc/Gmd9rWvV/DSC-1516.jpg",
    thumbnail: "https://i.postimg.cc/Gmd9rWvV/DSC-1516.jpg",
  },
  {
    original: "https://i.postimg.cc/L5SFLp4M/DSC-1580.jpg",
    thumbnail: "https://i.postimg.cc/L5SFLp4M/DSC-1580.jpg",
  },
  {
    original: "https://i.postimg.cc/Qxkx6fvb/DSC-1601.jpg",
    thumbnail: "https://i.postimg.cc/Qxkx6fvb/DSC-1601.jpg",
  },
  {
    original: "https://i.postimg.cc/sgHz8qmp/DSC-1615.jpg",
    thumbnail: "https://i.postimg.cc/sgHz8qmp/DSC-1615.jpg",
  },
  {
    original: "https://i.postimg.cc/zffBHTtk/IMG-6739.jpg",
    thumbnail: "https://i.postimg.cc/zffBHTtk/IMG-6739.jpg",
  },
  {
    original: "https://i.postimg.cc/wvCTrZ6W/IMG-6747.jpg",
    thumbnail: "https://i.postimg.cc/wvCTrZ6W/IMG-6747.jpg",
  },
  {
    original: "https://i.postimg.cc/Pxp52Tkp/IMG-6860.jpg",
    thumbnail: "https://i.postimg.cc/Pxp52Tkp/IMG-6860.jpg",
  },
  {
    original: "https://i.postimg.cc/k5h5QDLP/IMG-6864.jpg",
    thumbnail: "https://i.postimg.cc/k5h5QDLP/IMG-6864.jpg",
  },
  {
    original: "https://i.postimg.cc/kGWXPsG7/IMG-6872.jpg",
    thumbnail: "https://i.postimg.cc/kGWXPsG7/IMG-6872.jpg",
  },
  {
    original: "https://i.postimg.cc/ht24nfDQ/IMG-7339.jpg",
    thumbnail: "https://i.postimg.cc/ht24nfDQ/IMG-7339.jpg",
  },
  {
    original: "https://i.postimg.cc/GmL36kC1/IMG-7738.jpg",
    thumbnail: "https://i.postimg.cc/GmL36kC1/IMG-7738.jpg",
  },
  {
    original: "https://i.postimg.cc/bvdywprC/IMG-7759.jpg",
    thumbnail: "https://i.postimg.cc/bvdywprC/IMG-7759.jpg",
  },
  {
    original: "https://i.postimg.cc/9fSW4KqW/IMG-7775.jpg",
    thumbnail: "https://i.postimg.cc/9fSW4KqW/IMG-7775.jpg",
  },
  {
    original: "https://i.postimg.cc/W3ks08M0/IMG-8060.jpg",
    thumbnail: "https://i.postimg.cc/W3ks08M0/IMG-8060.jpg",
  },
  {
    original: "https://i.postimg.cc/fyQDCdKp/IMG-8069.jpg",
    thumbnail: "https://i.postimg.cc/fyQDCdKp/IMG-8069.jpg",
  },
  {
    original: "https://i.postimg.cc/L5kRbsQX/LAS-9417.jpg",
    thumbnail: "https://i.postimg.cc/L5kRbsQX/LAS-9417.jpg",
  },
  {
    original: "https://i.postimg.cc/jdXt6LJ3/LAS-9420.jpg",
    thumbnail: "https://i.postimg.cc/jdXt6LJ3/LAS-9420.jpg",
  },
  {
    original: "https://i.postimg.cc/2jnz6HjD/LAS-9433.jpg",
    thumbnail: "https://i.postimg.cc/2jnz6HjD/LAS-9433.jpg",
  },
  {
    original: "https://i.postimg.cc/pLLxsRfW/LAS-9456.jpg",
    thumbnail: "https://i.postimg.cc/pLLxsRfW/LAS-9456.jpg",
  },
  {
    original: "https://i.postimg.cc/tg1bHkv8/LAS-9531.jpg",
    thumbnail: "https://i.postimg.cc/tg1bHkv8/LAS-9531.jpg",
  },
  {
    original: "https://i.postimg.cc/pdgHVjgr/LAS-9566.jpg",
    thumbnail: "https://i.postimg.cc/pdgHVjgr/LAS-9566.jpg",
  },
  {
    original: "https://i.postimg.cc/0QDqzQsf/LAS-9656.jpg",
    thumbnail: "https://i.postimg.cc/0QDqzQsf/LAS-9656.jpg",
  },
  {
    original: "https://i.postimg.cc/wMk8kG3R/LAS-9660.jpg",
    thumbnail: "https://i.postimg.cc/wMk8kG3R/LAS-9660.jpg",
  },
  {
    original: "https://i.postimg.cc/qBcT0zQB/LAS-9663.jpg",
    thumbnail: "https://i.postimg.cc/qBcT0zQB/LAS-9663.jpg",
  },
  {
    original: "https://i.postimg.cc/RVMz04b7/LAS-9753.jpg",
    thumbnail: "https://i.postimg.cc/RVMz04b7/LAS-9753.jpg",
  },
  {
    original: "https://i.postimg.cc/DyB3JHtt/LAS-9755.jpg",
    thumbnail: "https://i.postimg.cc/DyB3JHtt/LAS-9755.jpg",
  },
  {
    original: "https://i.postimg.cc/qvqV8Qrg/LAS-9757.jpg",
    thumbnail: "https://i.postimg.cc/qvqV8Qrg/LAS-9757.jpg",
  },
  {
    original: "https://i.postimg.cc/QdzLS5Nm/LAS-9774.jpg",
    thumbnail: "https://i.postimg.cc/QdzLS5Nm/LAS-9774.jpg",
  },
  {
    original: "https://i.postimg.cc/prR3yJkW/LAS-9946.jpg",
    thumbnail: "https://i.postimg.cc/prR3yJkW/LAS-9946.jpg",
  },
  {
    original: "https://i.postimg.cc/d18zqx2N/LAS-9948.jpg",
    thumbnail: "https://i.postimg.cc/d18zqx2N/LAS-9948.jpg",
  },
  {
    original: "https://i.postimg.cc/Pqvg7xPj/LAS-9956.jpg",
    thumbnail: "https://i.postimg.cc/Pqvg7xPj/LAS-9956.jpg",
  },
  {
    original: "https://i.postimg.cc/9XYvKsFr/LAS-9959.jpg",
    thumbnail: "https://i.postimg.cc/9XYvKsFr/LAS-9959.jpg",
  },
  {
    original: "https://i.postimg.cc/NFDSX2P1/LAS-9971.jpg",
    thumbnail: "https://i.postimg.cc/NFDSX2P1/LAS-9971.jpg",
  },
];
const Images = () => {
  return (
    <div className={styles.container} id={"slike"}>
      <h1>Слике</h1>
      <ImageGallery items={images} />
    </div>
  )
}

export default Images;